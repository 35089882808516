import React, { Component } from "react";
import { connect } from 'react-redux';
import { setSelectedInvoice } from '../../../redux/action/OrganisationAction';
import IconDate from '../../../components/Icons/IconDate';
import IconCalendar from '../../../assets/svg/icon-calendar.svg';
import IconDropUp from '../../../components/Icons/IconDropUp';
import { numberFormat } from '../../../helpers/Number';
import moment from 'moment';
import EditableInput from "../../../components/EditableInput";
import ListInvoices from "../../../components/ListInvoices";
import { _reconcile, _unreconcile, _moveToCashAccount, _removeFromCashAccount } from '../../../services/BankRecord';
import InvoiceMetaData from "../../../components/InvoiceMetaData";
import InvoiceAttachment from "../../../components/InvoiceAttachment";
import TruncateString from "../../../components/TruncateString";
import { errorNotifcation } from "../../../helpers/errorHandle";
import iconUpload from '../../../assets/svg/icon-cloud-upload-bank.svg';
import { _presingAndUploadToS3, _saveInvoice } from '../../../services/Invoice';
import CONSTANTS, { SESSION_STORAGE_KEYS, IMAGE_FILE_OPTIONS, IMAGES_TYPES } from '../../../constants/constants';
import imageCompression from 'browser-image-compression';
import { Modal, Button} from 'react-bootstrap';

import "./Reconciliation.css";
import Logger from "../../../helpers/Logger";

import { toastr } from 'react-redux-toastr';

class Reconciliation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            startDate: null,
            endDate: null,
            displayFilter: true,
            displayInvoices: false,
            selectedInvoice: null,
            disableCashAction: false,
            label: "Importer justificatif",
            allowType: ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'],
            disabled: false,
            showVoucher: false,
            voucherContent: "",
        };

        this.iconDropArrow = React.createRef();
        this.resetFilterFields = this.resetFilterFields.bind(this);
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.props.onRef(this);
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    constructParam = () => {
        const { startDate, endDate, filter } = this.state;
        let param = {};
        if (startDate) param.startDate = startDate;
        if (endDate) param.endDate = endDate;
        if (filter) param.filter = filter;

        return param;
    }

    handleCloseVoucher = () => this.setState({showVoucher: false, voucherContent: ""});

    handleShowVoucher = () => {
        const voucherContent = this.buildVoucherContent();
        this.setState({showVoucher: true, voucherContent: voucherContent});
    }

    buildVoucherContent = () => {
        const accountNumber = this.props.selectedRecord.account.accountNumber;
        const reference = this.props.selectedRecord.reference;
        const description = this.props.selectedRecord.description;
        const bank = this.props.selectedRecord.account.bank.bankName;
        const debit = this.props.selectedRecord.debit;
        const credit = this.props.selectedRecord.credit;
        const operationDate = this.props.selectedRecord.operationDate;
        return `Compte : ${accountNumber} \nRéférence : ${reference} \nDescription : ${description} \nBanque : ${bank} \nDébit : ${debit} XAF \nCrédit : ${credit} XAF \nDate de l'opération : ${operationDate}`;
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.state.voucherContent);
        this.handleCloseVoucher();
    }

    handleChangeStartDate = (e) => {
        this.setState({
            startDate: e.target.value,
        }, () => {
            const param = this.constructParam();
            this.props.loadUnreconciledInvoices(true, param, +this.props.selectedRecord.id);
        });
    }

    handleChangeEndDate = (e) => {
        this.setState({
            endDate: e.target.value,
        }, () => {
            const param = this.constructParam();
            this.props.loadUnreconciledInvoices(true, param, +this.props.selectedRecord.id);
        });
    }

    handleSearch = (e) => {
        this.setState({
            filter: e.target.value,
        }, () => {
            const param = this.constructParam();
            this.props.loadUnreconciledInvoices(true, param, +this.props.selectedRecord.id);
        });
    }

    resetFilterFields = () => {
        this.setState({
            startDate: null,
            endDate: null,
            filter: '',
        })
    }

    showFilterZone = () => {
        if (this.state.selectedInvoice) this.props.closeAttachmentDisplay();
        this.props.setSelectedInvoice({});
        this.setState({
            displayFilter: true,
            selectedInvoice: null,
        }, () => {
            this.props.loadUnreconciledInvoices(true, {}, +this.props.selectedRecord.id);
        });
    }
    hideFilterZone = () => {
        this.setState({
            displayFilter: false,
            selectedInvoice: null,
            startDate: null,
            endDate: null,
            filter: '',
        });
    }
    displayFilterZone = () => {
        let elem = this.iconDropArrow.current;
        if (elem.classList.contains("rotate-z-180")) {
            elem.classList.remove("rotate-z-180");
            this.showFilterZone();
        } else {
            elem.classList.add("rotate-z-180");
            this.hideFilterZone();
        }
    }

    showInvoiceDetail = (selectedInvoice) => {
        if (this.props.attachmentDisplayed) this.props.onInvoiceItemClicked(selectedInvoice.attachment, true, selectedInvoice.contentType);
        let elem = this.iconDropArrow.current;
        if (!elem.classList.contains("rotate-z-180")) {
            elem.classList.add("rotate-z-180");
            this.hideFilterZone();
        }
        this.setState(() => ({
            selectedInvoice,
        }));
    }
    hideInvoiceDetail = () => {
        this.props.closeAttachmentDisplay();
        this.setState(() => ({
            selectedInvoice: null,
        }));
        let elem = this.iconDropArrow.current;
        if (elem.classList.contains("rotate-z-180")) {
            elem.classList.remove("rotate-z-180");
            this.showFilterZone();
        }
    }

    reconcileInvoice = async (invoiceData, notify = false) => {
        this.props.setModalReconcile(true);
        try {
            let organization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            const data = { invoiceId: invoiceData.id, bankRecordId: this.props.selectedRecord.id };
            const result = (await _reconcile(organization.id, +this.props.selectedRecord.id, data)).data;
            const param = this.constructParam();
            if (result) this.props.reloadAllInvoices(+this.props.selectedRecord.id, param);
            if(notify) toastr.success("", "Rapprochement effectué avec succès !");
        } catch (error) {
            Logger.error("ReconcileInvoice", "ReconcileInvoice Error", error);
            errorNotifcation(error?.response?.status, "Une erreur c'est produite pendant le rapprochement, veuillez reessayer plus tard.");
        } finally {
            this.props.setModalReconcile(false);
        }
    }
    unreconcileInvoice = async (invoiceId) => {
        this.props.setModalReconcile(true);
        try {
            let organization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            const result = (await _unreconcile(organization.id, +this.props.selectedRecord.id, invoiceId)).data;
            const param = this.constructParam();
            if (result) this.props.reloadAllInvoices(+this.props.selectedRecord.id, param);
            this.hideInvoiceDetail();
        } catch (error) {
            Logger.error(`Impossible de retirer l'entrée de caisse correspondante id ${this.props.selectedRecord.id}`, error);
            errorNotifcation(error?.response?.status, "Impossible de retirer l'entrée de caisse correspondante, veuillez reessayer plus tard.");
        } finally {
            this.props.setModalReconcile(false);
        }
    }

    computeReconciledAmount = (invoices) => {
        let reconciledAmount = 0;
        for (const invoice of invoices) {
            reconciledAmount += invoice.totalAmount
        }
        return reconciledAmount;
    }

    openFileDialog = () => {
        if (this.state.disabled) return;
        this.fileInputRef.current.click();
    }

    checkFileType = (fileType) => {
        const { allowType } = this.state;
        return allowType.find((type) => { return type ===  fileType });
    }

    onFilesAdded = async (e) => {
        this.props.setModalReconcile(true);
        this.setState({
            disabled: true,
            label: "Traitement en cours ...",
        });
        if (this.state.disabled) return;
        const files = [ ...e.target.files ];
        if(!files.length) return;
        const file = files[0];
        if(!this.checkFileType(file.type)){
            toastr.warning("Seuls les images et les PDFs peuvent être télécharger, veuillez vérifier les types de fichiers selectionnés");
            return;
        }
        let organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        const uploadedFile = await this.uploadedFilestoS3(organization.id, file);
        console.log(uploadedFile);
        const payload = this.constructPayload(uploadedFile);
        await this.createInvoice(payload);
    }

    resetValues = () => {
        this.fileInputRef.current.value = "";
        this.setState({
            files: null,
            label: "Importer justificatif",
            disabled: false,
        })
    }

    createInvoice = async (payload) => {
        let organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        _saveInvoice(organization.id, payload).then(async (res) => {
            await this.reconcileInvoice(res.data, true); 
        }).catch((err) => {
            const error = { ...err };
            Logger.error("Create Supporting document", "Create Supporting document Failed", error);
            errorNotifcation(error?.response?.status, "Echec d'enregistrement de la / les facture(s), veuillez reessayer ultérieurement");
        }).finally(() => {
            this.resetValues();
            this.setState({
                isDetailLoading: false,
            });
        });
    }

    uploadedFilestoS3 = async (orgId, file) => {
        const formData = new FormData();
        let finalFile = file;
        if(IMAGES_TYPES.find((type) => { return type ===  file.type })) {
            const options = {
                maxSizeMB: IMAGE_FILE_OPTIONS.MAX_SIZE_MB,
                maxWidthOrHeight: IMAGE_FILE_OPTIONS.MAX_WIDTH_OR_HEIGHT,
                useWebWorker: IMAGE_FILE_OPTIONS.USE_WEB_WORKER
            };
            try{
                finalFile = await imageCompression(file, options);
            }catch(error){
                const err = {...error};
                Logger.error("COMPRESSION_IMAGE_ERROR", `Can't compress the image: ${file.name}`, err);
                toastr.error("ERROR", `Erreur lors de l'envoie du fichier ${file.name}`);
            }
        }
        formData.append("scanFile", finalFile, file.name);
        const res = await _presingAndUploadToS3(orgId, formData);
        return {
            attachment: res.key,
            contentType: file.type,
        }
    }

    constructPayload = (data) => {
        return { ...data, source: CONSTANTS.INVOICE_SOURCE };
    }

    moveToCashAccount = async () => {
        this.props.setModalReconcile(true);
        try {
            this.setState({ disableCashAction: true });
            let organization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            const result = (await _moveToCashAccount(organization.id, +this.props.selectedRecord.id)).data;
            if (result) this.props.refreshAllRecords();
        } catch (error) {
            Logger.error(`Impossible de déplacer l'opération bancaire id ${this.props.selectedRecord.id} vers le compte de caisse`, error);
            errorNotifcation(error?.response?.status, "Echec de l'ajout en tant qu'entrée de caisse, veuillez reessayer plus tard.");
        } finally {
            this.props.setModalReconcile(false);
            this.setState({ disableCashAction: false });
        }
    }

    removeFromCashAccount = async () => {
        this.props.setModalReconcile(true);
        try {
            this.setState({ disableCashAction: true });
            let organization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            const result = (await _removeFromCashAccount(organization.id, +this.props.selectedRecord.id)).data;
            if(result) this.props.refreshAllRecords();
        } catch (error) {
            Logger.error("Impossible de déplacer l'enregistrement vers le compte de caisse", "Erreur de transfert d'enregistrement ", error);
            errorNotifcation(error?.response?.status, "Une erreur s'est produite lors du déplacement de l'enregistrement vers le compte de caisse.");
        }finally {
            this.props.setModalReconcile(false);
            this.setState({ disableCashAction: false });
        }
    }

    renderBankRecordDetail = () => {
        const type = (this.props.selectedRecord.credit) ? 'Crédit' : 'Débit';
        const amount = (this.props.selectedRecord.credit) ? this.props.selectedRecord.credit : this.props.selectedRecord.debit;
        const date = moment(new Date(this.props.selectedRecord.operationDate || this.props.selectedRecord.createdAt)).format('DD MMMM YYYY');

        return (
            <div className="card-item-detail m-2">
                <div className="item-row">
                    <span className="text-modal-bank"> Compte </span>
                    <span className="bold text-modal-bank"> {this.props.selectedRecord?.account?.accountName || this.props.selectedRecord?.account?.accountNumber} </span>
                </div>
                {
                    this.props.selectedRecord.reference ? <div className="item-row">
                        <span className="text-modal-bank"> Référence </span>
                        <span className="bold text-modal-bank">
                            <TruncateString text={this.props.selectedRecord.reference} maxWidth={150} />
                        </span>
                    </div> : null
                }
                <div className="item-row">
                    <span className="text-modal-bank"> Date </span>
                    <span className="bold text-modal-bank">
                        <span className='pr-2'> <img src={IconCalendar} alt='Date icon' width='15px' height='15px' /> </span>
                        {date}
                    </span>
                </div>
                <div className="item-row">
                    <span className="text-modal-bank"> {type} </span>
                    <span className="bold text-modal-bank"> {this.props.selectedRecord?.account?.currency + ' ' + numberFormat(amount)} </span>
                </div>
                <div className="item-row">
                    <span className="text-modal-bank"> Description </span>
                </div>
                <div className="item-row bg-light-grey">
                    <span className="text-modal-bank"> {this.props.selectedRecord?.description} </span>
                </div>
                {/* {
                    !this.props.selectedRecord.invoices?.length && this.props.selectedRecord.debit ?
                    <div className="item-row">
                        <span className="text-modal-bank"> Entrée de caisse </span>
                        <span className="bold text-modal-bank">
                            {
                                !this.props.selectedRecord.cashRecord ?
                                <button className="btn btn-outline-warning" type="button" onClick={this.moveToCashAccount} disabled={this.state.disableCashAction}> Ajouter </button>
                                : <button className="btn btn-outline-warning" type="button" onClick={this.removeFromCashAccount} disabled={this.state.disableCashAction}> Retirer </button>
                            }
                        </span>
                    </div> : null
                } */}
                {
                    !this.props.selectedRecord.invoices?.length && this.props.selectedRecord.debit ?
                    <div className="color-bank-feature">
                        <input
                            ref={this.fileInputRef}
                            className="hidden"
                            type="file"
                            onChange={this.onFilesAdded}
                            accept=".png, .jpeg, .jpg, .pdf"
                            multiple
                            disabled={this.state.disabled}
                        />
                        <button className="upload color-bank-feature" onClick={this.openFileDialog} disabled={this.state.disabled}>
                            <img src={iconUpload} alt="Attach" width={19} height={'auto'} />
                            <span>{ this.state.label }</span>
                        </button> 
                    </div> : null
                }
                <Modal show={this.state.showVoucher} onHide={this.handleCloseVoucher}>
                    <Modal.Header closeButton>
                        <Modal.Title>Détails du bon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> <pre>{this.state.voucherContent}</pre></Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={this.copyToClipboard}>
                            Copier dans le presse-papiers
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                <div className="item-row">
                    <span className="text-modal-bank"> Générer le bon</span>
                    <span className="bold text-modal-bank">
                        <button className="btn btn-outline-warning" type="button" onClick={this.handleShowVoucher} > Générer </button>
                    </span>
                </div>

            </div>
        )
    }

    renderFilterZone = () => {
        if (this.state.displayFilter) {
            return (
                <div className="zone-filter">
                    <div className="period-form p-10">
                        <div className="date-form">
                            <EditableInput type={'customDate'} value={this.state.startDate} editable state={'preview'} placeholder={'Debut'} withIcon={<IconDate />} globalClassName={'date-filter pl-10 form form-control'} onChange={this.handleChangeStartDate} />
                        </div>
                        <div className="date-form">
                            <EditableInput type={'customDate'} placement="bottom-end" value={this.state.endDate} editable state={'preview'} placeholder={'Fin'} withIcon={<IconDate />} globalClassName={'date-filter pl-10 form form-control'} onChange={this.handleChangeEndDate} />
                        </div>
                    </div>
                    <div className="filter-form pl-10 pr-10">
                        <input type="search" onChange={this.handleSearch} className="invoice-filter" placeholder={"Filtrer"} value={this.state.filter} />
                    </div>
                    <div className="p-10 unreconciled-invoice-rows">
                        <ListInvoices data={this.props.unreconciledInvoices} onClick={this.reconcileInvoice} onHandleAttachment={this.props.onInvoiceItemClicked} loading={this.props.loadingUnreconciledInvoices} />
                    </div>
                </div>
            )
        }

    }

    renderReconciledInvoices = () => {
        if (this.props.reconciledInvoices.length > 0) {
            return (
                <div>
                    <div className="separator mt-20"></div>
                    {
                        (this.props.reconciledInvoices.length > 1) ?
                            <div className="d-flex align-item-center justify-content-between pr-2 pl-2 mt-1">
                                <div className='text-header-bank bold mt-1'>Montant reconcilié</div>
                                <div className='text-header-bank bold mt-1'>
                                    {this.props.selectedRecord?.account?.currency + ' ' + numberFormat(this.computeReconciledAmount(this.props.reconciledInvoices))}
                                </div>
                            </div> : null
                    }
                    <div className="p-10">
                        <ListInvoices
                            data={this.props.reconciledInvoices}
                            onClick={this.reconcileInvoice}
                            unreconcile={this.unreconcileInvoice}
                            onHandleAttachment={this.props.onInvoiceItemClicked}
                            detail={true}
                            onHandleDetail={this.showInvoiceDetail}
                        />
                    </div>
                </div>
            )
        }
    }

    renderInvoiceDetail = () => {
        const { selectedInvoice } = this.state;
        if (selectedInvoice) {
            return (
                <div className='fade-in'>
                    <div className="separator mt-10"></div>
                    <div className="d-flex justify-content-end">
                        <button className="btn-drop-up" onClick={this.hideInvoiceDetail}>
                            <IconDropUp />
                        </button>
                    </div>
                    <div className="item-row mt-1">
                        <span className="bold text-header-bank">FACTURE</span>
                        <span>
                            <img src={IconCalendar} alt='Date icon' width='15px' height='15px' />
                            <span className='ml-2 text-modal-bank'>{selectedInvoice.date ? moment(selectedInvoice.date).format('DD MMM YYYY') : moment(selectedInvoice.createdAt).format('DD MMM YYYY')}</span>
                        </span>
                    </div>
                    <div className="card-item-detail m-2">
                        <div className="item-row">
                            <span className="text-modal-bank"> Fournisseur </span>
                            <span className="bold text-modal-bank"> {selectedInvoice.deliver} </span>
                        </div>
                        <div className="item-row">
                            <span className="text-modal-bank"> Montant HT </span>
                            <span className="bold text-modal-bank">
                                {selectedInvoice.currency + ' ' + numberFormat(selectedInvoice.netAmount)}
                            </span>
                        </div>
                        <div className="item-row">
                            <span className="text-modal-bank"> TVA </span>
                            <span className="bold text-modal-bank">
                                {selectedInvoice.currency + ' ' + numberFormat(selectedInvoice.vat)}
                            </span>
                        </div>
                        <div className="item-row">
                            <span className="text-modal-bank"> Montant total </span>
                            <span className="bold text-modal-bank">
                                {selectedInvoice.currency + ' ' + numberFormat(selectedInvoice.totalAmount)}
                            </span>
                        </div>
                    </div>
                    <InvoiceMetaData invoice={selectedInvoice} className="text-modal-bank" header="text-header-bank" />
                    <InvoiceAttachment attachment={selectedInvoice.attachment} contentType={selectedInvoice.contentType} onHandleAttachment={this.props.onInvoiceItemClicked} />
                </div>
            )
        }
    }

    render = () => {
        return (
            <div className="reconciliation">
                {
                    this.renderBankRecordDetail()
                }
                {
                    this.renderReconciledInvoices()
                }
                {
                    this.renderInvoiceDetail()
                }
                <div className="separator mt-10"></div>
                <div className="d-flex align-item-center justify-content-between pr-2 pl-2 mt-1">
                    <div className='text-header-bank bold mt-1'>Filter</div>
                    <button ref={this.iconDropArrow} className="btn-drop-up" onClick={this.displayFilterZone}>
                        <IconDropUp />
                    </button>
                </div>
                {
                    this.renderFilterZone()
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unreconciledInvoices: state.organizations.unreconciledInvoices,
        reconciledInvoices: state.organizations.reconciledInvoices,
        selectedRecord: state.accounts.selectedRecord,
    }
}

export default connect(mapStateToProps, { setSelectedInvoice })(Reconciliation)