import React, { Component } from "react";
import { connect } from 'react-redux';
import { addClass, removeClass } from '../../helpers/DOM';
import { ContentTypes } from '../../constants/constants';

import "./InvoiceAttachment.css";

class InvoiceAttachment extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true,
        }
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        let container = document.querySelector(".minimized-attachment-preview");
        let attachment = document.querySelector(".minimized-attachment-preview img");
        
        if(attachment?.addEventListener) {
            attachment.addEventListener("load", () => {
                this.autoSize(attachment, container);
                if(this._isMounted) this.setState({ loading: false });
                removeClass(attachment, "opacity-0");
                addClass(attachment, "fade-in");
            });
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    onHandleAttachment = () => {
        this.props.onHandleAttachment(this.props.attachment, true);
    }

    autoSize = (attachment, container) => {
        const containerSize = this._sizeOf(container);
        const imageSize = this._sizeOf(attachment);
        if(imageSize.width > imageSize.height && imageSize.width > containerSize.width){
            addClass(attachment, "height-auto");
        }else if(imageSize.width > imageSize.height && imageSize.width < containerSize.width){
            addClass(attachment, "width-auto");
        }else if(imageSize.width < imageSize.height && imageSize.height > containerSize.height){
            addClass(attachment, "height-auto");
        }else if(imageSize.width < imageSize.height && imageSize.height < containerSize.height){
            addClass(attachment, "width-auto");
        }else{
            addClass(attachment, "width-height-auto");
        }
    }
    _sizeOf(selector){
        const imageWidth = selector.offsetWidth;
        const imageHeight= selector.offsetHeight;

        return { "width" : imageWidth, "height" : imageHeight};
    }

    renderLoader = () => {
        if(this.state.loading){
            return (
                <div className="box-loader">
                    <div className="b-load"></div>
                </div> 
            )
        }
        return (<></>)
    }

    render = () => {
        if(this.props.attachment){
            if( this.props.contentType === ContentTypes.pdf){
                return (
                    <div className="minimized-attachment-preview m-2">
                        <div className="overlay-box" onClick={this.onHandleAttachment}></div>
                        <object data={this.props.attachment}
                            width='50%' 
                            height='90%'
                            type="application/pdf">
                        </object>
                    </div>
                )
            } 
            return (
                <div className="minimized-attachment-preview m-2" onClick={this.onHandleAttachment}>
                    { this.renderLoader() }
                    <img className='attachmentPreview opacity-0' src={this.props.attachment}  alt='Preview attachement' />
                </div>
            )
        }
    }

}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {})(InvoiceAttachment)