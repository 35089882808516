import axios, {baseInstance} from '../config/axios';
import CONSTANTS from "../constants/constants";

export const _presingAndUploadToS3 = async (orgId, formData) => {
    const presignResponse = (await _presignedRequest(orgId, formData.get("scanFile").name)).data;
    await _uploadFileToS3(presignResponse, formData.get("scanFile"));
    return presignResponse.fields;
}

export const _saveInvoice = async (orgId, data) => {
    return await axios.post(`/organizations/${orgId}/invoices`, data);
}

export const _presignedRequest = async (orgId, originalFileName) => {
    const reqUrl = `/organizations/${orgId}/presign-post?originalFileName=${originalFileName}&context=${CONSTANTS.INVOICE_CONTEXT}`;
    return await axios.post(reqUrl);
}

export const _uploadFileToS3 = async (presignedPostData, file) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });

    formData.append("file", file);

    return await baseInstance.post(presignedPostData.url, formData);
}

export const _createMultipleInvoices = async (orgId, payload) => {
    return await axios.post(`/organizations/${orgId}/invoices/create/multiple`, payload);
}

export const _getInvoiceById = async (orgId, id) => {
    return await axios.get(`/organizations/${orgId}/invoices/${id}`);
}

export const _updateInvoice = async (orgId, id, invoiceData)=> {
    return await axios.put(`/organizations/${orgId}/invoices/${id}`, invoiceData);
}

export const _getInvoicesByParams = async (orgId, params) => {
    const param = {
        params: {
            ...params,
            orgId,
        }
    }
    return await axios.get(`/organizations/${orgId}/invoices`, param);
}