import React, { Component } from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import { setDefaultOrganization, updateOrganizationBill } from "../../redux/action/OrganisationAction";
import "simple-react-validator/dist/locale/fr";
import SimpleReactValidator from "simple-react-validator";
import Countries from "country-data";
import DatePicker from "react-datepicker";
import { _saveBill, _generateBillTitle, _updateBill } from "../../services/Bill";
import { _getCustomers } from "../../services/Customer";
import { FUNCTIONALITIES_CODE } from "../../constants/AuthConstants";
import UserAuth from "../../helpers/auth";
import Logger from '../../helpers/Logger';
import Loader from '../../components/Loader';
import { Row, Col } from "react-bootstrap";
import CustomDropdown from '../../components/CustomDropdown';
import IconClose from "../../components/Icons/IconClose";
import IconSave from '../../components/Icons/SaveIcon';
import Currencies from "../../constants/Currencies";
import IconPlus from '../../components/Icons/IconPlus';
import IconBack from '../../components/Icons/IconBack';
import IconEdit from '../../components/Icons/EditIconComponent';
import {numberFormat} from "../../helpers/Number";
import { SESSION_STORAGE_KEYS, MESURE_UNIT_OPTIONS } from '../../constants/constants';
import Alert from 'react-bootstrap/Alert';
import {staticNode} from "../../helpers/DOM";

import "./Bills.css";

export class EditBill extends Component {

    constructor(props) {
        super(props);
        this.state = {
            organization: {},
            title: "",
            date: null,
            paymentDate: null,
            description: "",
            orgCountry: "",
            status: "En attente",
            discount: 0,
            customerId: null,
            customer: {
                name: "",
                address: "",
                phone1: "",
                phone2: "",
                postalCode: "",
                city: "",
                country: "",
                niu: "",
                tradeRegister: "",
                organizationId: JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG)).id,
            },
            billLines: [
                {
                    quantity: 0,
                    totalAmount: 0,
                    product: {
                        name: "",
                        unitOfMeasure: "",
                        unitPrice: 0,
                        vatRate: 0,
                        organizationId: JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG)).id,
                    }
                }
            ],
            billPayments: [{
                label: "",
            }],
            currency: "",
            htTotal: 0,
            baseTaxable: 0,
            vat: 0,
            ttcTotal: 0,
            clients: [],
            isLoading: false,
            isPmLoading: false,
            isError: false,
            msgError: '',
            errorMsgTable: '',
            errorMsgPaymentMode: '',
        };
        this.validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'fr'});
    }

    componentDidMount = () => {
        const organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        this.getCustomers();
        let {customer, billLines} = this.state;
        customer.organizationId = organization.id;
        billLines.forEach((elm) => {
            elm.product.organizationId = organization.id;
        });
        this.generateBillTitle(organization.id);
        this.setState({ organization, customer, billLines });
        staticNode(".hoverlay-controls", 90, "offset");
        
        this.populateForm();
    }

    populateForm = () => {
        if(this.props.preview){ 
            this.setState({
                ...this.state,
                ...this.props.history.location.state.payload,
            });
        }
    }

    generateBillTitle = (orgId) => {
        _generateBillTitle(orgId).then((res) => {
            this.setState({ title: res.data })
        });
    }

    getCustomers = () => {
        const organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        _getCustomers(organization.id, {}).then((res) => {
            this.setState({
                clients: res.data.rows,
            });
        }).catch((err) => {
            Logger.error("GET CUSTOMERS FOR BILL", "Can't get customers for create bill", err);
        });
    }

    saveBill = async () => {
        if(UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_CREATE) && this.validator.allValid()){
            let defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            const payload = {
                organizationId: defaultOrg.id,
                title: this.state.title,
                date: this.state.date,
                paymentDate: this.state.paymentDate,
                description: this.state.description,
                status: this.state.status,
                discount: 0,
                customerId: this.state.customerId,
                customer: {...this.state.customer, organizationId: defaultOrg.id},
                billLines: [...this.state.billLines],
                billPayments: [...this.state.billPayments],
                currency: this.state.currency,
            };
            this.setState({ isLoading: true });
            try{
                await _saveBill(defaultOrg.id, payload);
                this.props.history.push("/billing");
            }catch(e){
                Logger.error("CREATE BILL", "Can't create Bill", e);
            }finally{
                this.setState({ isLoading: false, clients: [] });
            }
        }else{
            this.setState({ isError: true, msgError: 'Veillez remplir correctement tous les champs obligatoirs!'});
            this.validator.showMessages();
        }
    }

    updateBill = async () => {
        if(UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_UPDATE)){
            if(this.validator.allValid()){
                let defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
                const payload = {
                    id: this.state.id,
                    organizationId: defaultOrg.id,
                    title: this.state.title,
                    date: this.state.date,
                    paymentDate: this.state.paymentDate,
                    description: this.state.description,
                    status: this.state.status,
                    discount: 0,
                    customerId: this.state.customerId,
                    customer: {...this.state.customer, organizationId: defaultOrg.id},
                    billLines: [...this.state.billLines],
                    billPayments: [...this.state.billPayments],
                    currency: this.state.currency,
                };
                this.setState({ isLoading: true });
                try{
                    const res = await _updateBill(defaultOrg.id, payload);
                    this.props.updateOrganizationBill(res.data);
                    this.props.history.push(this.cleanPath(this.props.match.path)+"preview");
                }catch(e){
                    Logger.error("UPDATE BILL", "Can't update Bill", e);
                }finally{
                    this.setState({ isLoading: false, clients: [] });
                }
            }else{
                this.setState({ isError: true, msgError: 'Veillez remplir correctement tous les champs obligatoirs!'});
                this.validator.showMessages();
            }
        }else{
            this.setState({ isError: true, msgError: "Vous n'êtes pas habilité à modifier une facture!"});
        }
    }

    canselEdit = () => {
        this.props.history.push("/billing");
    }

    handleChange = (e, key = null, callback = null) => {
        if(key){
            let targetValue = this.state[e.target.name];
            targetValue[key] = e.target.value;
            this.setState({ [e.target.name] : targetValue });
        }else{
            this.setState({ [e.target.name]: e.target.value });
        } 
    }

    handleChangeCustomer = (e, key = null) => {
        this.handleChange(e, key);
        const customer = this.state.clients.find((elm) => elm.name === e.target.value);
        if(customer?.id) this.setState({ customer, customerId: customer.id });
    }

    handleChangeFloatValue = (e, i, key = null) => {
        let billLines = [...this.state.billLines];
        const regex = /,/ig;
        let qty = 0;
        qty = e.target.value.replaceAll(regex, ".");
        key ? billLines[i][e.target.name][key] = qty : billLines[i][e.target.name] = qty;
        this.setState({ billLines });
    }

    handleChangeTable = (e, i, isNumber = false, key = null) => {
        let billLines = [...this.state.billLines];

        if(key){
            if(isNumber){
                isNaN(+e.target.value) ? billLines[i][e.target.name][key]  = 0 : billLines[i][e.target.name][key]  = parseFloat(+e.currentTarget.value);
            }else{
                billLines[i][e.target.name][key] = e.target.value;
            }
            billLines[i] = this.setTotalRow(billLines[i]);
        }else{
            if(isNumber){
                isNaN(+e.target.value) ? billLines[i][e.currentTarget.name] = 0 : billLines[i][e.currentTarget.name] = parseFloat(+e.currentTarget.value);
            }else{
                billLines[i][e.target.name] = e.target.value;
            }
            billLines[i] = this.setTotalRow(billLines[i]);
        }
        const { htTotal, baseTaxable, vat, ttcTotal } = this.setTotals(billLines);
        this.setState({ billLines, htTotal, baseTaxable, vat, ttcTotal });
    }

    handleChangeTableVat = (e, i, isNumber = false, key = null) => {
        if(+e.target.value <= 100 && +e.target.value >= 0){
            this.handleChangeTable(e, i, isNumber, key);
            this.setState({errorMsgTable: ""});
        }else{
            this.setState({errorMsgTable: "La TVA doit etre comprise entre 0 et 100"});
        }
    }

    setTotalRow = (objRow) => {
        objRow.totalAmount = parseFloat(objRow.quantity) * parseFloat(objRow.product.unitPrice);
        return objRow;
    }

    setTotals = (rows) => {
        let htTotal = 0;
        let baseTaxable = 0;
        let vat = 0;
        let ttcTotal = 0;

        rows.forEach((elm) => {
            htTotal += elm.totalAmount;
            baseTaxable += (elm.product.vatRate > 0) ? elm.totalAmount : 0;
            vat += (+elm.totalAmount * elm.product.vatRate) / 100;
        });
        vat = (!isNaN(vat)) ? vat : 0;
        ttcTotal = htTotal + vat;

        return { htTotal, baseTaxable, vat, ttcTotal };
    }

    removeRow = (i) => {
        let billLines = [...this.state.billLines];
        billLines.splice(i, 1);
        const { htTotal, baseTaxable, vat, ttcTotal } = this.setTotals(billLines);
        this.setState({ billLines, htTotal, baseTaxable, vat, ttcTotal });
    }

    removePaymentRow = (i) => {
        let billPayments = [...this.state.billPayments];
        billPayments.splice(i, 1);
        this.setState({ billPayments });
    }


    renderTableLines = () => {
        return this.state.billLines.map((elm, i) => {
            return ( <tr className={` ${i === 0 ? (this.isErrorMsg(this.validator.message('[Linge 1 du tableau]', this.state.billLines[0].product.name, 'required')).isError ? 'msg-error' : '') : ''}`}>
                <td>
                    <div className="form-box top-dashed bottom-dashed left-dashed h-25 position-relative overflow-hidden"> 
                        { (i > 0  && !this.props.preview) ? <button className="remove-row-btn" onClick={() => this.removeRow(i)}><IconPlus width={9} height={9} color="rgb(159, 12, 12)" style={{transform: "rotate(45deg)"}} /> </button> : <></> }
                        <input type="text" name="product" value={elm.product.name} className={`${ (i > 0  && !this.props.preview) ? 'pl-push-hover' : ''}`} placeholder="" onChange={(e) => this.handleChangeTable(e, i, false, "name")} disabled={this.props.preview}/>
                    </div>
                </td>
                
                <td><div className="form-box top-dashed bottom-dashed left-dashed h-25"><CustomDropdown name="product" optionKey="name" options={MESURE_UNIT_OPTIONS} value={elm.product.unitOfMeasure}  onSelect={(e) => this.handleChangeTable(e, i, false, "unitOfMeasure")} placeholder=" " placeholderFilter="Filtrer" other disabled={this.props.preview}/></div></td>
                <td><div className="form-box top-dashed bottom-dashed left-dashed h-25"><input type="text" className="input-align-right plr-10" name="quantity" value={elm.quantity} onChange={(e) => this.handleChangeFloatValue(e, i)} onBlur={(e) => this.handleChangeTable(e, i, true)}  placeholder="" disabled={this.props.preview}/></div></td>
                <td><div className="form-box top-dashed bottom-dashed left-dashed h-25"><input type="text" className="input-align-right plr-10" name="product" value={elm.product.unitPrice} onChange={(e) => this.handleChangeFloatValue(e, i, "unitPrice")} onBlur={(e) => this.handleChangeTable(e, i, true, "unitPrice")} placeholder="" disabled={this.props.preview}/></div></td>
                <td className="sm-hidden"><div className="form-box top-dashed bottom-dashed left-dashed h-25"> <input type="text" className="input-align-right plr-10" name="product" value={this.state.billLines[i].product.vatRate} onChange={(e) => this.handleChangeFloatValue(e, i, "vatRate")} onBlur={(e) => this.handleChangeTableVat(e, i, true, "vatRate")} placeholder="" disabled={this.props.preview}/></div> </td>
                <td><div className="form-box dashed h-25"> <div className="d-flex align-items-center justify-content-end plr-10 w-100-percent text-total-row">{numberFormat(elm.totalAmount, 2, ".", " ")}</div></div></td>
            </tr>)
        });
    }

    addRow = () => {
        const lastIndex = this.state.billLines.length - 1;
        if(this.state.billLines[lastIndex].product.name.length){
            const newLine = {
                quantity: 0,
                totalAmount: 0,
                product: {
                    name: "",
                    unitOfMeasure: "",
                    unitPrice: 0,
                    vatRate: 0,
                    organizationId: this.state.organization.id,
                }
            };
            this.setState({
                billLines: [ ...this.state.billLines, {...newLine}],
                errorMsgTable: "",
            });
        }else{
            this.setState({errorMsgTable: "Vous devez renseigner au moins la designation!"});
        }
       
    }

    addPayment = () => {
        const lastIndex = this.state.billPayments.length - 1;
        if(this.state.billPayments[lastIndex].label.length){
            const newLine = {
                label: "",
            };
            this.setState({
                billPayments: [ ...this.state.billPayments, {...newLine}],
                errorMsgPaymentMode: "",
            });
        }else{
            this.setState({errorMsgPaymentMode: "Vous devez renseigner au moins un mode de paiement!"})
        }
    }

    renderPaymentMode = () => {
        return this.state.billPayments.map((elm, i) => {
            return (
                <div className={`form-box dashed h-25 mt-2 position-relative ${i === 0 ? (this.isErrorMsg(this.validator.message('[Methode 1]', this.state.billPayments[0].label, 'required')).isError ? 'msg-error' : '') : ''}`} key={i}>
                    { (i > 0  && !this.props.preview) ? <button className="remove-row-btn fades" onClick={() => this.removePaymentRow(i)}><IconPlus width={9} height={9} color="rgb(159, 12, 12)" style={{transform: "rotate(45deg)"}} /> </button> : <></> }
                    <input type="text" name="billPayments" value={elm.label} className={`${ (i > 0 && !this.props.preview) ?  'pl-push-hover' : ''}`} placeholder="Mode de paiement" onChange={(e) => this.handleChangePm(e, i)} disabled={this.props.preview}/>
                </div>
            );
        });
    }

    handleChangePm = (e, i, key) => {
        const billPayments = [...this.state.billPayments];
        billPayments[i].label = e.target.value;
        this.setState({ billPayments });
    }

    closeErrorLabel = () => {
        this.setState({ isError: false });
    }

    isErrorMsg = (message) => {
        return message ? {isError: true, message} : {isError: false, message: null};
    }

    goBack = () => {
        this.props.history.goBack();
    }

    gotoList = () => {
        this.props.history.push("/billing");
    }

    cleanPath = (path) => {
        return path.split(":")[0];
    }

    gotoUpdate = () => {
        if(UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_UPDATE)){
            this.props.history.push(this.cleanPath(this.props.match.path)+"update");
        }
    }

    render() {
        return (
            <div className="edit-bill-content flex-1-auto">
                <div className="hoverlay-controls p-2">
                    <div className="orientation-left d-flex align-items-center">
                        {this.props.preview ? 
                        <button className='btn-plus-bill mr-2' onClick={this.gotoList}>
                            <IconBack  color="#FFFFFF" />
                        </button> :
                        this.props.update ? 
                        <button onClick={this.updateBill} className="btn-save-bill d-flex align-items-center mr-2 p2" disabled={!UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_UPDATE)}>
                            <span className="mr-2">Mettre a jour</span>
                            <IconSave color="#0C9F23" width={10} height={10} /> 
                        </button> :
                        <button onClick={this.saveBill} className="btn-save-bill d-flex align-items-center mr-2 p2" disabled={!UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_CREATE)}>
                            <span className="mr-2">Enregistrer</span>
                            <IconSave color="#0C9F23" width={10} height={10} /> 
                        </button>
                        }
                        {this.props.preview ? 
                        <button className="btn-edit-bill mr-2" onClick={this.gotoUpdate}  disabled={!UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_UPDATE)}>
                            <IconEdit color="#A7A7A7" size={15} />
                        </button> :
                        this.props.update ? 
                        <button className="btn-cancel-bill mr-2" onClick={this.goBack}>
                            <IconClose width={8} height={8} />
                        </button> :
                        <button className="btn-cancel-bill mr-2" onClick={this.canselEdit}>
                            <IconClose width={8} height={8} />
                        </button>
                        }
                        <Loader style={{width: "25px", height: "25px", borderTopColor: "#OC9F23"}} enable={this.state.isLoading} className="mr-2" />
                        {this.state.isError ? <Alert variant='danger' bsPrefix="alert bill-alert" onClose={this.closeErrorLabel} dismissible > {this.state.msgError} </Alert> : <></>}
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    {this.props.preview ?
                    <button className='btn-plus-bill mr-2' onClick={this.gotoList}>
                        <IconBack  color="#FFFFFF" />
                    </button> :
                    this.props.update ? 
                    <button onClick={this.updateBill} className="btn-save-bill d-flex align-items-center mr-2 p2" disabled={!UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_UPDATE)}>
                        <span className="mr-2">Mettre a jour</span>
                        <IconSave color="#0C9F23" width={10} height={10} /> 
                    </button> :
                    <button onClick={this.saveBill} className="btn-save-bill d-flex align-items-center mr-2 p2" disabled={!UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_CREATE)}>
                        <span className="mr-2">Enregistrer</span>
                        <IconSave color="#0C9F23" width={10} height={10} /> 
                    </button>
                    }
                    {this.props.preview ?
                    <button className="btn-edit-bill mr-2" onClick={this.gotoUpdate}  disabled={!UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_READ)}>
                        <IconEdit color="#A7A7A7" size={15} />
                    </button> :
                    this.props.update ? 
                    <button className="btn-cancel-bill mr-2" onClick={this.goBack}>
                        <IconClose width={8} height={8} />
                    </button> :
                    <button className="btn-cancel-bill mr-2" onClick={this.canselEdit}>
                        <IconClose width={8} height={8} />
                    </button>
                    }
                    <Loader style={{width: "25px", height: "25px", borderTopColor: "#OC9F23"}} enable={this.state.isLoading} className="mr-2" />
                    {this.state.isError ? <Alert variant='danger' bsPrefix="alert bill-alert" onClose={this.closeErrorLabel} dismissible > {this.state.msgError} </Alert> : <></>}
                </div>
                <div className="bill-content-box mt-3">
                    <Row className={`p-3 bill-header ${!this.props.preview ? 'bill-display' : ''}`}>
                        <Col md={6} sm={12}>
                            <div className="expeditor box-infos cursor-pointer">
                                {/* Logo companny */}
                                <div className="box-logo d-flex align-items-center dashed p-2">
                                    <div className="logo-area"> <img src={this.state.organization.logo} alt="logo" width="100%" height="auto" /></div>
                                </div>
                                {/* Form expeditor */}
                                <div className="user-form dashed mt-1">
                                    <div className="form-box"><input type="text" name="orgName" value={this.state.organization.name} className="txt-title" placeholder="Société" disabled /></div>
                                    <div className="form-box top-dashed">
                                        <div className="form-inline"><input type="text" name="phone1" value={this.state.organization.phone1} placeholder="Adresse ligne 1" disabled /></div>
                                        <div className="form-inline left-dashed"><input type="text" name="phone2" value={this.state.organization.phone2} placeholder="Adresse ligne 2" disabled /></div>
                                    </div>
                                    <div className="form-box top-dashed">
                                        <div className="form-box w-100"><input type="text" name="orgPobox" value={this.state.organization.codePostal} placeholder="Code postal" disabled /></div>
                                        <div className="form-inline left-dashed"><input type="text" name="orgTown" value={this.state.organization.town} placeholder="Ville" disabled /></div>
                                    </div>
                                    <div className="form-box top-dashed"><input type="text" name="orgCountry" className="" value={this.state.organization.country} placeholder="Pays" disabled /></div>
                                    <div className="form-box top-dashed"><input type="text" name="orgNiu" className="" value={this.state.organization.numNiu} placeholder="NIU" disabled /></div>
                                    <div className="form-box top-dashed"><input type="text" name="orgRc" className="" value={this.state.organization.rcs} placeholder="RC" disabled /></div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} sm={12}>
                            <div className="d-flex justify-content-md-end justify-content-sm-start">
                                <div className="bill-header-right box-infos cursor-pointer">
                                {/* Bill Header */}
                                <div className="user-form dashed mt-1">
                                    <div className="form-box"><input type="text" name="title" value={this.state.title} onChange={this.handleChange} className="" placeholder="Intitulé de la facture" readOnly /></div>
                                    <div className="form-box top-dashed pl-1">
                                        <div className="form-inline sm-txt-light">Date d'émission </div>
                                        <div className="form-inline left-dashed">
                                            <DatePicker
                                                selected={ this.state.date ? new Date(this.state.date) : new Date() }
                                                name="date"
                                                onChange={(val) => this.handleChange({target: {value: val, name: "date"}})}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="dd/MM/yyyy"
                                                todayButton="Aujourd'hui"
                                                placeholderText={`${moment().format("DD/MM/YYYY")}`}
                                                popperPlacement={"bottom-end"}
                                                popperClassName="zi-9999" 
                                                disabled={this.props.preview}
                                            />
                                        </div>    
                                    </div>
                                    <div className="form-box top-dashed pl-1">
                                        <div className="form-inline sm-txt-light">Date de règlement </div>
                                        <div className="form-inline left-dashed">
                                            <DatePicker
                                                selected={ this.state.paymentDate ? new Date(this.state.paymentDate) : new Date() }
                                                name="paymentDate"
                                                onChange={(val) => this.handleChange({target: {value: val, name: "paymentDate"}})}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="dd/MM/yyyy"
                                                todayButton="Aujourd'hui"
                                                placeholderText={`${moment().format("DD/MM/YYYY")}`}
                                                popperPlacement={"bottom-end"}
                                                popperClassName="zi-9999"
                                                disabled={this.props.preview}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Customer */}
                                <div className="user-form dashed customer">
                                    <div className={`form-box ${this.isErrorMsg(this.validator.message('[Nom Client]', this.state.customer.name, 'required')).isError ? 'msg-error' : ''}`}>
                                        <CustomDropdown 
                                            name="customer" 
                                            optionKey="name" 
                                            options={this.state.clients} 
                                            value={this.state.customer.name} 
                                            onSelect={(e) => this.handleChangeCustomer(e, "name")}
                                            placeholder="Client" 
                                            placeholderAuther="Nouveau client"
                                            placeholderFilter="Filtrer" 
                                            other 
                                            disabled={this.props.preview}
                                        />
                                    </div>
                                    <div className="form-box top-dashed">
                                        <div className="form-inline"><input type="text" name="customer" value={this.state.customer.phone1} onChange={(e) => this.handleChange(e, "phone1")}  placeholder="Adresse ligne 1" disabled={this.props.preview} /></div>
                                        <div className="form-inline left-dashed"><input type="text" name="customer" value={this.state.customer.phone2} onChange={(e) => this.handleChange(e, "phone2")} placeholder="Adresse ligne 2" disabled={this.props.preview} /></div>
                                    </div>
                                    <div className="form-box top-dashed">
                                        <div className="form-box w-100"><input type="text" name="customer" value={this.state.customer.postalCode} onChange={(e) => this.handleChange(e, "postalCode")} placeholder="Code postal" disabled={this.props.preview} /></div>
                                        <div className="form-inline left-dashed"><input type="text" name="customer" value={this.state.customer.city} onChange={(e) => this.handleChange(e, "city")} placeholder="Ville" disabled={this.props.preview} /></div>
                                    </div>
                                    <div className="form-box top-dashed"><CustomDropdown name="customer" optionKey="name" options={Countries.countries.all} value={this.state.customer.country} onSelect={(e) => this.handleChange(e, "country")} placeholder="Pays" placeholderFilter="Filtrer" other disabled={this.props.preview} /></div>
                                    <div className="form-box top-dashed"><input type="text" name="customer" value={this.state.customer.niu} onChange={(e) => this.handleChange(e, "niu")} className="" placeholder="NIU" disabled={this.props.preview}/></div>
                                    <div className="form-box top-dashed"><input type="text" name="customer" value={this.state.customer.tradeRegister} onChange={(e) => this.handleChange(e, "tradeRegister")} className="" placeholder="RC" disabled={this.props.preview}/></div>
                                </div>
                                </div>

                            </div>  
                        </Col>
                    </Row>
                    <Row className={`p-3 mt-1 ${!this.props.preview ? 'bill-display' : ''}`}>
                        <Col md={6} sm={12} className="">
                            <div className="dashed">
                                <div className={`form-box ${this.isErrorMsg(this.validator.message('[Devise]', this.state.currency, 'required')).isError ? 'msg-error' : ''}`}>
                                    <div className={"form-inline left-dashed"}><CustomDropdown name="currency" prefixText="Devise" optionKey="currency" options={Currencies} value={this.state.currency} onSelect={this.handleChange} placeholder="Devise:" placeholderFilter="Filtrer" other disabled={this.props.preview} /></div>
                                </div>
                                <div className="form-box top-dashed"><input type="text" name="description" value={this.state.description}  onChange={this.handleChange} className="" placeholder="Description de la facture" disabled={this.props.preview}/></div>
                            </div>
                            
                        </Col>
                        <Col md={6} sm={12}></Col>
                    </Row>
                    <Row className={`p-3 ${!this.props.preview ? 'bill-display' : ''}`}>
                        <Col md={12}>
                            <table className="table-bill">
                                <thead>
                                    <tr>
                                        <th align="left"><span>Designation</span></th>
                                        <th className="input-align-left w-unit-mesure"><span className="d-block">Unité de mesure</span></th>
                                        <th className="input-align-right w-qty"><span className="d-block">Quantité</span></th>
                                        <th className="input-align-right w-pu"><span className="d-block">Prix unitaire</span></th>
                                        <th className="input-align-right w-vat sm-hidden"><span className="d-block">TVA (%)</span></th>
                                        <th className="input-align-right w-pt"><span className="d-block">Prix total</span></th>
                                    </tr>
                                </thead>
                                <tbody classNane="bill-body-table">
                                    {this.renderTableLines()}
                                </tbody>
                            </table>
                            {!this.props.preview ? <div className="mt-3 d-flex align-items-center">
                                <button className="btn-add-row mb-2" onClick={this.addRow}> <IconPlus width={9} height={9} /> <span className="ml-2" >Ajouter une ligne</span> </button>
                                <span className="msg-table-error ml-2"> {this.state.errorMsgTable} </span>
                            </div> : <></>}
                        </Col>
                    </Row>
                    <Row className={`p-3 ${!this.props.preview ? 'bill-display' : ''}`}>
                        <Col md={12} className="d-flex justify-content-end">
                            <div className="box-infos">
                                <div className="d-flex align-items-center justify-content-between pl-1 pr-2 h-25">
                                    <div className="label-title">Total HT</div>
                                    <div>
                                        <span className="text-amount mr-2">{numberFormat(this.state.htTotal, 2, ".", " ")}</span>
                                        <span className="text-amount">{this.state.currency}</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between pl-1 pr-2 mt-2 h-25">
                                    <div className="label-title">TVA</div>
                                    <div>
                                        <span className="text-amount mr-2">{numberFormat(this.state.vat, 2, ".", " ")}</span>
                                        <span className="text-amount">{this.state.currency}</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between pl-1 pr-2 mt-2 h-30 bg-bill-primary-light">
                                    <div className="label-title txt-bold bill-primary-color">TOTAL TTC</div>
                                    <div>
                                        <span className="text-amount mr-2 bill-primary-color">{numberFormat(this.state.ttcTotal, 2, ".", " ")}</span>
                                        <span className="text-amount bill-primary-color">{this.state.currency}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="border-dashed mt-5"></div>
                    <Row className={`p-3 bill-footer ${!this.props.preview ? 'bill-display' : ''}`}>
                        <Col md={12}>
                        { this.renderPaymentMode() }
                        {!this.props.preview ? <div className="mt-3 mb-3 d-flex align-items-center">
                            <button className="btn-add-row mb-2" onClick={this.addPayment}> <IconPlus width={9} height={9} /> <span className="ml-2" >Ajouter une ligne</span> </button>
                            <span className="msg-table-error ml-3"> {this.state.errorMsgPaymentMode} </span>
                        </div> : <></>}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        organizations: state.organizations.organizations,
        defaultOrg: state.organizations.defaultOrganization,
    }
}

export default connect(mapStateToProps, { setDefaultOrganization, updateOrganizationBill })(EditBill)
